<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Çalışma Sınıfı Güncelle
          </h6></b-col>
        </b-row>
        <b-form-group label="Kod">
          <b-form-input
            v-model="formData.code"
            placeholder="Kod"
          />
        </b-form-group>
        <b-form-group label="Başlık">
          <b-form-input
            v-model="formData.title"
            placeholder="Başlık"
          />
        </b-form-group>
      </b-card-text>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'WorkTypesEdit',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      detailForm: false,
      submitStatus: false,
      formData: {
        title: null,
      },
    }
  },
  computed: {
    workStatusData() {
      return this.$store.getters['workTypes/getWork_type']
    },
    saveStatus() {
      const status = this.$store.getters['workTypes/getWork_typeSaveStatus']
      return status
    },
  },
  watch: {
    workStatusData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getWorkType()
  },
  methods: {
    getWorkType() {
      this.$store.dispatch('workTypes/work_typeView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('workTypes/work_typeSave', this.formData)
    },
  },
}
</script>
